<template>
  
    <div class="top-bar">
      <div class="container">
        <div class="col-12 text-end">
            <p><a href="tel:+5615077838">Call us at 1 KOBL + RRUFT</a></p>
        </div>
      </div>
    </div>
    <nav class="navbar bg-light navbar-light navbar-expand-lg">
      <div class="container">
        
        <router-link to="/" class="navbar-brand">
          <img src="../assets/icons/website_logo.png" alt="AJ Surf Co" title="AJ Surf Co"/>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
<!--
            <li class="nav-item">
              <router-link to="/software" class="nav-link">Software</router-link>
            </li>
-->
            <li class="nav-item">
              <router-link to="/hardware" class="nav-link">PC Hardware</router-link>
            </li>
            
            <li class="nav-item">
              <router-link to="/3d_printing" class="nav-link">3D Printing</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/games" class="nav-link">Video Games</router-link>
            </li>            
<!--            <li class="nav-item">
              <router-link to="/snorkling_map" class="nav-link">RC</router-link>
            </li>
-->
            <li class="nav-item">
              <router-link to="/water_sports" class="nav-link">Snorkeling</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About</router-link>
            </li>                    
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact Us</router-link>
            </li>
          </ul>

        </div>

      </div>
    </nav>
</template>
  
<style scoped>
    @import "@/assets/css/nav-bar.css";
</style>