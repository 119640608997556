import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/software',
    name: 'software',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "software" */ '../views/SoftwareView.vue')
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: () => import(/* webpackChunkName: "hardware" */ '../views/HardwareView.vue')
  },
  {
    path: '/gaming_hardware',
    name: 'gaming_hardware',
    component: () => import(/* webpackChunkName: "gaming_hardware" */ '../views/GamingHardwareView.vue')
  },
  {
    path: '/games',
    name: 'games',
    component: () => import(/* webpackChunkName: "games" */ '../views/GamesView.vue')
  },
  {
    path: '/3d_printing',
    name: '3d_printing',
    component: () => import(/* webpackChunkName: "3d_printing" */ '../views/ThreeDimPrintingView.vue')
  },
  {
    path: '/water_sports',
    name: 'water_sports',
    component: () => import(/* webpackChunkName: "water_sports" */ '../views/WaterSportsView.vue')
  },
  {
    path: '/snorkling_map',
    name: 'snorkling_map',
    component: () => import(/* webpackChunkName: "snorkling_map" */ '../views/SnorklingMapView.vue')
  },  
  {
    path: '/surfing',
    name: 'surfing',
    component: () => import(/* webpackChunkName: "surfing" */ '../views/SurfingView.vue')
  },
  {
    path: '/thank_you',
    name: 'thank_you',
    component: () => import(/* webpackChunkName: "thank_you" */ '../views/ThankYouView.vue')
  },    
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactUsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  }
})

export default router
